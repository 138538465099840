@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,500;0,6..96,700;1,6..96,500;1,6..96,700&display=swap');

html, body {
  padding:0;
  margin: 0;
  background: black;
}

p {
  color:white;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.slide {
  padding: 2em;
  margin: 0 auto;
  font-size: 2em;
}
.slide p {
  overflow: hidden;
  animation: typing 3.5s steps(40, end),
}

.img-slide {
  margin: 0 auto;
}

.App {
  width: 100vw;
  height:100vh;
  padding:0pt;
  margin: 0pt;
  overflow: hidden;
  font-family: "Bodoni Moda";
  text-align: center;
  display: flex;
  align-items: center;
}

.tooltip {
  position: absolute;
  top: 1em;
  background: rgba(200,0,0,0.5);
  color:black;
  backdrop-filter: blur(10px);
  z-index: 1000;
  border-radius: 0.5em;
  display: inline-block;
  left: 30%;
  right: 30%;
}
.tooltip .tooltip-body {
  padding-left: 1em;
  padding-right: 1em;
}
.tooltip .tooltip-header {
  backdrop-filter: brightness(20%);
  width: 100%;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 2px;
}
.tooltip .tooltip-header button {
  background: transparent;
  border: 1px solid rgb(156, 156, 156);
  border-radius: 5px;
  color: rgb(156, 156, 156);
  cursor: pointer;
}
.tooltip .tooltip-header button:hover {
  border: 1px solid rgb(200,200,200);
  color: rgb(200,200,200);
}
.tooltip p {
  color: black;
}

.slide.intro .top {
  animation: fadeIn 3s ease-in;
}
.slide.intro p:not(.top):not(.no-fade) {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-delay: 3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.button {
  position: absolute;
  height: 100vh;
  margin: 0;
  width: 10vw;
  color: white;
  display: flex;
  align-items: center;
  background: transparent;
  text-align: center;
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.25s;
}
.button span {
  margin: 0 auto;
  font-size: 3em;
  text-align: center;
}
.button:hover {
  background: rgba(100,100,100,0.5);
  cursor: pointer;
  opacity: 1;
}
.button.back {
  left: 0;
}
.button.forward {
  right: 0;
}
.slide.citations a {
  text-decoration: none;
  color: white;
  font-size: 12pt;
}
.slide.citations h3 {
  color: white;
}
.slide.citations {
  line-height: 10pt;
  text-align: left;
}